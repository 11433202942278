import {spreadDigits, spreadUnits} from '../js/main';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const profitLoss = {
    mounted() {
       // console.log("mixins/profitLoss.js mounted() starting. this.namespace=", this.namespace);
    },
    newSeries: {}, // <-- non-reactive property
    profitLossEvent: {}, // <-- non-reactive property
    profitLossLabel: {}, // <-- non-reactive property
    data: function () {
        return{
        }
    },
    computed: {
        addProfitLoss() {
            return this.$store.state[this.namespace].addProfitLoss;
        },
        buySell() {
            return this.$store.state[this.namespace].buySell;
        }
    },
    watch: {
        addProfitLoss: function (addProfitLoss) {
            console.log("watch addProfitLoss = ", addProfitLoss);
            if (!addProfitLoss) {
                this.clearProfitLoss();
            } else {
                this.$options.chart.map.getKey("profit-loss-label").text = addProfitLoss ? "Click open and close on chart." : "";
            }
        },
        buySell: function () {
            // console.log("watch buySell = ", buySell);
            if (this.addProfitLoss) {
                this.createEquation();
            }
        }
    },
    methods: {
        clearProfitLoss() {
          //  console.log("clearProfitLoss() starting. this.namespace=", this.namespace);
            // console.trace();
            this.$options.newSeries.bulletsContainer.removeChildren();
            let mainAxis = this.$options.chart.map.getKey("main-axis");
            this.$options.newSeries.data = [];
            mainAxis.axisRanges.removeValue(mainAxis.axisRanges.values.find(x => x.id === "open-value-range"));
            mainAxis.axisRanges.removeValue(mainAxis.axisRanges.values.find(x => x.id === "close-value-range"));
            mainAxis.axisRanges.removeValue(mainAxis.axisRanges.values.find(x => x.id === "label-range"));

            this.$options.chart.map.getKey("profit-loss-label").text = this.addProfitLoss ? "Click open and close on chart." : "";
            this.$options.newSeries.invalidate();
        },
        createEquation() {
            let selected = this.$store.state[this.namespace].selected[0];
            //  console.log("selected=", selected);
            let digits = spreadDigits(selected, this.$store.state[this.namespace].instrument);
            // console.log("digits=", digits);
            let units = spreadUnits(selected, this.$store.state[this.namespace].instrument);
            //  console.log("units=", units);

            if (typeof this.$options.profitLossLabel === 'undefined') {
                this.$options.profitLossLabel = this.chart.createChild(am4core.Label);
            }
            let values = this.$options.newSeries.data.sort((a, b) => a.date - b.date).map(x => parseFloat(x.newValue.toFixed(digits)));
            let diff = values[1] - values[0];
            // console.log("values=", values);

            let buySellFactor = this.buySell === "buy" ? 1 : -1;
            //  console.log("buySellFactor=", buySellFactor);

            let color = buySellFactor * diff > 0 ? "black" : "red";
            let leftParensForNegs = values[0] < 0 ? "(" : "";
            let rightParensForNegs = values[0] < 0 ? ")" : "";
            this.$options.profitLossLabel.fill = "black";

            let profitLossLabelText;
            if (units !== "USD") {
                profitLossLabelText = "P/L = (" + values[1] + " - " + leftParensForNegs + values[0] + rightParensForNegs + ") * $" +
                        buySellFactor * this.$store.state[this.namespace].unitMove[0] + " = [" + color + "]" + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(buySellFactor * this.$store.state[this.namespace].unitMove[0] * diff) + "[/]";
            } else {
                profitLossLabelText = "P/L = " + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(values[1]) + " - " + leftParensForNegs + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(values[0]) + rightParensForNegs + " = [" + color + "]" + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(diff) + "[/]";
            }
            this.$options.profitLossLabel.text = profitLossLabelText;
        },
        profitLoss() {
            // console.log("profitLoss() starting.");
            if (typeof this.$options.profitLossEvent.dispose === 'function') {
                this.$options.profitLossEvent.dispose();
            }

            let selected = this.$store.state[this.namespace].selected[0];
            //  console.log("selected=", selected);
            let digits = spreadDigits(selected, this.$store.state[this.namespace].instrument);
            // console.log("digits=", digits);

            this.$options.newSeries = this.$options.chart.series.push(new am4charts.LineSeries());
            this.$options.newSeries.id = "profit-loss-series";
            this.$options.newSeries.data = [];
            this.$options.newSeries.dataFields.dateX = "date";
            this.$options.newSeries.dataFields.valueY = "newValue";
            this.$options.newSeries.interpolationDuration = 0;
            this.$options.newSeries.stroke = "black";
            this.$options.newSeries.hiddenInLegend = true;

            let bullet = this.$options.newSeries.bullets.push(new am4charts.CircleBullet());
            bullet.circle.fillOpacity = 0.5;
            bullet.circle.fill = "lightblack";
            bullet.circle.stroke = "black";
            bullet.circle.strokeWidth = 1;
            bullet.circle.radius = 4;

            let valueRange;

            this.$options.profitLossLabel = this.$options.chart.createChild(am4core.Label);
            this.$options.profitLossLabel.id = "profit-loss-label";
            this.$options.profitLossLabel.fontSize = 10;
            this.$options.profitLossLabel.fontWeight = "normal";
            this.$options.profitLossLabel.align = "center";
            this.$options.profitLossLabel.isMeasured = false;
            if (this.$store.state[this.namespace].instrument === "future") {
                this.$options.profitLossLabel.x = am4core.percent(20);
            } else {
                this.$options.profitLossLabel.x = am4core.percent(20);
            }
            this.$options.profitLossLabel.y = am4core.percent(.5);
            this.$options.profitLossLabel.horizontalCenter = "middle";
            let interaction = am4core.getInteraction();

            // console.log("Object.values(this.$options.profitLossEvent)=", Object.values(this.$options.profitLossEvent));
            if (typeof Object.values(this.$options.profitLossEvent).length > 0) {
                //   console.log("this.$options.profitLossEvent=", this.$options.profitLossEvent);
                this.$options.profitLossEvent.dispose();
                // console.log("this.$options.profitLossEvent=", this.$options.profitLossEvent);
            }

            let that = this;
            this.$options.profitLossEvent = interaction.events.on("up", function (event) {
                // console.log("event=", event);
                // console.log("that.$options.newSeries.data.length=", that.$options.newSeries.data.length);
                let dateAxis = that.$options.chart.map.getKey("date-axis");
                let mainAxis = that.$options.chart.map.getKey("main-axis");
                // console.log("that.$store.state[" + that.namespace + "]=", that.$store.state[that.namespace]);
                if (that.$options.chart.cursor.visible && that.$store.state[that.namespace].addProfitLoss) {
                    /*   if (typeof that.$options.profitLossLabel !== 'undefined') {
                     console.log("that.$options.profitLossLabel is defined.");
                     that.$options.profitLossLabel.dispose();
                     }*/
                    if (that.$options.newSeries.data.length > 1) {
                        that.$options.newSeries.data = [];
                        mainAxis.axisRanges.removeValue(mainAxis.axisRanges.values.find(x => x.id === "open-value-range"));
                        mainAxis.axisRanges.removeValue(mainAxis.axisRanges.values.find(x => x.id === "close-value-range"));
                        mainAxis.axisRanges.removeValue(mainAxis.axisRanges.values.find(x => x.id === "label-range"));
                        that.$options.profitLossLabel.text = "Click open and close on chart.";

                        // console.log("bullets=", that.$options.newSeries.bullets.length);
                        that.$options.newSeries.bullets.getIndex(0).disabled = true;
                        that.$options.newSeries.invalidateData();
                    } else {
                        that.$options.profitLossLabel.text = "Click open and close on chart.";
                        let point = am4core.utils.documentPointToSprite(event.pointer.point, that.$options.chart.seriesContainer);
                        let cursorPosition = dateAxis.pointToPosition(point);
                        let date = dateAxis.positionToDate(cursorPosition);
                        let value = mainAxis.yToValue(point.y);
                        // console.log("date=", date, " value=", value);

                        if (value > mainAxis.min && value < mainAxis.max) {
                            if (value > mainAxis.min && value < mainAxis.max) {
                                that.$options.newSeries.data.push({date: date, newValue: value});

                                valueRange = mainAxis.axisRanges.create();
                                valueRange.value = value;
                                if (that.$options.newSeries.data.length == 1) {
                                    valueRange.id = "open-value-range";
                                } else {
                                    valueRange.id = "close-value-range";
                                }
                                valueRange.grid.stroke = "black";
                                valueRange.grid.strokeWidth = 1;
                                valueRange.grid.strokeOpacity = 1;
                                valueRange.grid.strokeDasharray = "4,4";

                                valueRange.label.text = value.toFixed(digits);
                                valueRange.label.inside = true;
                                valueRange.label.align = "center";
                                valueRange.label.horizontalCenter = "middle"
                                valueRange.label.fontSize = 10;
                                valueRange.label.fontWeight = "normal";
                                valueRange.label.fill = "black";

                                // console.log("this.$options.newSeries.data.length=", that.$options.newSeries.data.length);
                                if (that.$options.newSeries.data.length === 0 || that.$options.newSeries.data.length === 1) {
                                    that.$options.profitLossLabel.text = "Click open and close on chart.";
                                }

                                if (that.$options.newSeries.data.length === 2) {
                                    that.createEquation();
                                }
                                that.$options.newSeries.bullets.getIndex(0).disabled = false;
                                that.$options.newSeries.invalidateData();
                            }
                        }
                    }
                }
            });

            let dateAxis = this.$options.chart.map.getKey("date-axis");
            dateAxis.events.on("selectionextremeschanged", function () {
               // console.log("profitLoss() selectionextremeschanged");
                that.clearProfitLoss();
            });
        }
    }
};

export default profitLoss;
