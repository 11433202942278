import moment from 'moment';

function removeLevels(chart) {
    let mainAxis = chart.map.getKey('main-axis');
    mainAxis.axisRanges.clear();
}

function addLevels(chart, generalForm) {
    console.log("addLevels() starting.");
   // console.trace();
    removeLevels(chart);

    let levels = generalForm.chartParameters.levels;
    // console.log("generalForm=", generalForm);
    // console.log("levels=", levels);

    let dateAxis = chart.map.getKey('date-axis');
    let mainAxis = chart.map.getKey('main-axis');

    // let lastDate = dateAxis.max;
  //  console.log("generalForm=", generalForm);
  //  console.log("generalForm.playback=", generalForm.playback);
  //  console.log("generalForm.playback.end=", generalForm.playback.end);
  //  console.log("generalForm.chartParameters.end=", generalForm.chartParameters.end);
    if (typeof generalForm.playback !== 'undefined' && 
    (moment(generalForm.playback.end).isAfter(moment(generalForm.chartParameters.end)) || typeof generalForm.chartParameters.end == 'undefined')) {
        dateAxis.max = moment(generalForm.playback.end).valueOf();
        //  lastDate = dateAxis.max;
    } else {
        dateAxis.max = moment(generalForm.chartParameters.end).valueOf();
        //  lastDate = dateAxis.max;
    }
    // console.log("lastDate=", lastDate);

    let ranges = [];
    if (typeof levels === 'undefined') {
        alert("There is no level data.");
    } else {
        levels.forEach(function (level, i) {
            //  console.log("level", level, " i=", i);
            ranges[i] = mainAxis.axisRanges.create();
            ranges[i].value = level.from;
            ranges[i].endValue = level.to;
            ranges[i].axisFill.fill = level.color;
            ranges[i].axisFill.fillOpacity = 0.4;
            ranges[i].grid.strokeOpacity = 0;
        });
    }
}

export {addLevels, removeLevels};