import {/*spreadDigits, */removeNaNs} from '../main';
import {sma} from './am4charts.main';
import * as am4charts from "@amcharts/amcharts4/charts";

function removeSma(chart) {
    console.log("removeSma() starting.");
    let index = chart.series.indexOf(chart.map.getKey("sma-series"));
    console.log("index=", index);
    if (index !== -1) {
        chart.series.removeIndex(index).dispose();
    }
}

function addSma(chart, generalForm) {
    console.log("addSma() starting.");
    removeSma(chart);

   // let selected = generalForm.selected[0];
   // let digits = spreadDigits(selected, generalForm.instrument);
   // let formatString = "#." + "0".repeat(digits);

    let period = generalForm.smaLength;
    let mainData = chart.map.getKey("main-series").data;
    console.log("removeNaNs(mainData)=", removeNaNs(mainData));

    let sdSeries = sma(removeNaNs(mainData), period);
    console.log("sdSeries=", sdSeries);

    let smaSeries = chart.series.push(new am4charts.LineSeries());
    smaSeries.id = "sma-series";
    smaSeries.data = sdSeries;
    smaSeries.dataFields.valueY = "close_sma_" + period;
    smaSeries.dataFields.openValueY = "close_sma_" + period;
    smaSeries.dataFields.dateX = "date";
    smaSeries.name = "SMA_"+ period;
    smaSeries.strokeWidth = 1;
    smaSeries.stroke = "darkblue";
    smaSeries.fillOpacity = 0.0;
    smaSeries.stacked = false;
   // smaSeries.legendSettings.labelText = "[{color}]sma_20: {valueY.formatNumber('" + formatString + "')}";
    smaSeries.hiddenInLegend = true;
    // smaSeries.yAxis = chart.map.getKey("main-axis");
}

export {addSma, removeSma};