import $ from "jquery";
import { spreadDigits } from "../main";
import * as am4charts from "@amcharts/amcharts4/charts";
import moment from "moment";

function removeSeasonal(years, chart) {
    // console.log("removeSeasonal() starting.");
    // console.log("removing " + years + "-year seasonal.");
    let series = chart.map.getKey(years + "-y");
    if (chart.series.indexOf(series) > -1) {
        chart.series.removeIndex(chart.series.indexOf(series)).dispose(); //series.hide();  
    }
}

function addSeasonals(seasonals, chart, generalForm, seasonalDataArray, seasonalColorOptions, chartObject) {
    // console.log("addSeasonals() starting. seasonals=", seasonals);
    // console.log("this.seasonalDataArray=", this.seasonalDataArray);
    //  console.log("generalForm=", generalForm);
    const seas10 = { value: 10, color: 'darksalmon'};
    const seas15 = { value: 15, color: 'crimson'};
    const seas20 = { value: 20, color: 'brown'};
    const seas30 = { value: 30, color: 'black'};
    seasonalColorOptions[0].value = 5;
    seasonalColorOptions[1] = seas10;
    seasonalColorOptions[2] = seas15;
    seasonalColorOptions[3] = seas20;
    seasonalColorOptions[4] = seas30;
    let digits = spreadDigits(generalForm.selected[0], generalForm.instrument);
    // console.log("digits=", digits);
    let formatString = "#." + "0".repeat(digits);

    if (typeof seasonalDataArray !== 'undefined') {
        let selected = generalForm.selected[0];
        //  console.log("selected=", selected);

        let digits = 1 + spreadDigits(selected);
        // console.log("digits=", digits);
        // console.log("this.seasonalDataArray=", [...this.seasonalDataArray]);
        for (var i = 0; i < seasonals.length; i++) {
            let id = seasonals[i] + "-y";
            removeSeasonal(seasonals[i], chart);

            var series = chart.series.push(new am4charts.LineSeries());
            series.id = id;
            series.dataFields.valueY = seasonals[i];
            series.dataFields.dateX = "date";
            series.name = seasonals[i] + "-year seasonal:";
            series.strokeWidth = 1;
            series.stroke = seasonalColorOptions.find(x => x.value == seasonals[i]).color;
            series.fill = series.stroke;
            series.fillOpacity = 0.0;
            series.stacked = false;
            series.legendSettings.labelText = "[{color}] {id}: {valueY}";
            series.tooltipText = generalForm.chartParameters.balloons ? seasonals[i] + "-y: {valueY.value.formatNumber('" + formatString + "')}" : "";
            series.hiddenInLegend = true;
            series.data = seasonalDataArray.map(x => {
                return {
                    date: moment(x.date, "YYYYMMDD").format("YYYY-MM-DD"),
                    [seasonals[i]]: parseFloat(x[seasonals[i]]).toFixed(digits)
                };
            }).filter(x => !isNaN(x[seasonals[i]]));
            console.log("series.data =", series.data);
        }

        let mainAxis = chart.map.getKey("main-axis");
        series.events.once("datavalidated", () => {
           // console.log("startendchanged");
            //chart.zoomAxes(chart.yAxes, {start: 0, end: 1});
            // console.log("mainAxis._minReal=", mainAxis._minReal);
            //  console.log("mainAxis._maxReal=", mainAxis._maxReal);
            mainAxis.zoomToValues(mainAxis._minReal, mainAxis._maxReal);
            // let dateAxis = chart.map.getKey("date-axis");
            // dateAxis.zoomToDates(0, 1);
            chartObject.initialZoom();

        });
    }
}

function getSeasonalData(newSeasonals, chart, generalForm, seasonalDataArray, seasonalColorOptions, chartObject) {
    // console.log("getSeasonalData() starting. newSeasonals=", newSeasonals);
    // this.$store.commit(this.namespace + '/setLoading', true);
    // console.trace();
    // console.log("generalForm=", generalForm);
    generalForm.seasonals = newSeasonals;
    delete generalForm.browserSideOnly;
    delete generalForm.playback;
    var json = JSON.stringify(generalForm, null, 2);
    $.ajax({
        url: chartObject.$store.state.siteData.baseUrl + chartObject.$store.state.siteData.contextName + "/StandardSeasonalsServlet",
        type: "POST",
        data: { "requestJson": json },
        success: function (data) {
            // that.$store.commit(that.namespace + '/setLoading', false);
            // console.log("data=", data);
            let parsedData = JSON.parse(data);
            // console.log("seasonal parsedData=", parsedData);

            if (typeof parsedData.dataset !== 'undefined') {
                let returnedSeasonals = parsedData.dataset.map(x => parseInt(x.split('-')[0]));
                // console.log("returnedSeasonals=", returnedSeasonals);

                // let digits = 1 + spreadDigits(generalForm.selected[0]);
                // console.log("digits=", digits);

                let additionalSeasonalDataArray = Object.keys(parsedData.values).map(date => ({ date: parseInt(date), ...parsedData.values[date] }));
                // console.log("additionalSeasonalDataArray=", [...additionalSeasonalDataArray]);
                let concatenatedSeries = [...seasonalDataArray, ...additionalSeasonalDataArray];
                // console.log("concatenatedSeries=", concatenatedSeries);
                let uniqueDates = [...new Set(concatenatedSeries.map(x => x.date))].sort();
                // console.log("uniqueDates=", uniqueDates);

                seasonalDataArray = uniqueDates.map(date => (
                    concatenatedSeries.filter(x => x.date == date)
                        .reduce((combinedBar, bar) => ({ ...combinedBar, ...bar }), {})
                ));
                // console.log("that.seasonalDataArray=", that.seasonalDataArray);

                addSeasonals(returnedSeasonals, chart, generalForm, seasonalDataArray, seasonalColorOptions, chartObject);
            }
        },
        fail: function (data) {
            console.log("ajax call failed.");
            $("#progress").hide();
            $("#button").show();
            console.log(data);
        }
    });
}

function setSeasonalTooltips(chart, balloons, generalForm) {
    let digits = spreadDigits(generalForm.selected[0], generalForm.instrument);
    console.log("digits=", digits);
    let formatString = "#." + "0".repeat(digits);

    let seasonalSeries = chart.series.values.filter(series => series.id.includes("-y"));
    console.log("seasonalSeries=", seasonalSeries);

    seasonalSeries.forEach(series => {
        series.tooltipText = balloons ? series.id + ": {valueY.value.formatNumber('" + formatString + "')}" : "";
    });
}

export { getSeasonalData, addSeasonals, removeSeasonal, setSeasonalTooltips };