import moment from 'moment';
import * as am4core from "@amcharts/amcharts4/core";
//import * as am4charts from "@amcharts/amcharts4/charts";

function hideTradingPeriod(chart) {
   // console.log("hideTradingPeriod() starting.");
    let dateAxis = chart.map.getKey("date-axis");

    dateAxis.axisRanges.removeValue(dateAxis.axisRanges.values.find(x => x.id === "open-range"));
    dateAxis.axisRanges.removeValue(dateAxis.axisRanges.values.find(x => x.id === "close-range"));
}

function showTradingPeriodIndicator(chart, generalForm, showLabels = true) {
  //  console.log("showTradingPeriodIndicator() starting.");
    hideTradingPeriod(chart);
    if (generalForm.showTradingPeriod) {
        var openDateObj = moment(generalForm.open, "YYYY-MM-DD").add(12, 'hours');
        var closeDateObj = moment(generalForm.close, "YYYY-MM-DD").add(12, 'hours');

        if (openDateObj.isBefore(closeDateObj)) {
            let dateAxis = chart.map.getKey("date-axis");
            let  open = dateAxis.axisRanges.create();
            open.id = "open-range";
            open.date = openDateObj.toDate();
            open.endDate = closeDateObj.toDate();
            open.grid.stroke = chart.flipLines ? "red" : "green";
            open.grid.strokeWidth = 2;
            open.grid.strokeOpacity = 1;
            open.axisFill.fill = am4core.color("#ffb01f");
            open.axisFill.fillOpacity = 0.4;

            let  close = dateAxis.axisRanges.create();
            close.id = "close-range";
            close.date = closeDateObj.toDate();
            close.grid.stroke = chart.flipLines ? "green" : "red";
            close.grid.strokeWidth = 2;
            close.grid.strokeOpacity = 1;

            if (showLabels) {
                let openLabel = "\nopen: " + moment(openDateObj).format("MMM D, YYYY");
                open.label.text = openLabel;
                open.label.fill = chart.flipLines ? "red" : "green";
                open.label.inside = true;
                open.label.valign = "middle";
                open.label.rotation = 270;
                open.label.verticalCenter = "middle";
                open.label.horizontalCenter = "left";
               // open.label.fontSize = 13;
                open.label.fontWeight = "normal";

                let closeLabel = "\nclose: " + moment(closeDateObj).format("MMM D, YYYY");
                close.label.text = closeLabel;
                close.label.fill = chart.flipLines ? "green" : "red";
                close.label.valign = "middle";
                close.label.inside = true;
                close.label.rotation = 270;
                close.label.verticalCenter = "middle"
                close.label.horizontalCenter = "left"
               // close.label.fontSize = 13;
                close.label.fontWeight = "normal";
            }
            /*    
             
             if (programVue.$store.state.generalForm.chartParameters.showMapHoverLocationOnSeasonal) {
             var mapCursorOpenDateObj = new Date(open); //.getTime() + 43200000; // The 43,200,000 shifts the guide from 12:00 am t0 12:00 pm .
             var mapCursorCloseDateObj = new Date(close); //.getTime() + 43200000;
             mapCursorCloseDateObj.setHours(mapCursorCloseDateObj.getHours() + 20);
             mapCursorOpenDateObj.setHours(mapCursorOpenDateObj.getHours() + 20);
             var mapCursorOpenLabel = "open: " + moment(mapCursorOpenDateObj).format("MMM D, YYYY");
             var mapCursorCloseLabel = "close: " + moment(mapCursorCloseDateObj).format("MMM D, YYYY");
             
             // console.log("chart=", chart);
             if (typeof chart !== "undefined") {
             chart.panels[0].categoryAxis.guides.push({
             id: "mapCursorOpen",
             date: mapCursorOpenDateObj,
             lineAlpha: 1.0,
             lineColor: chart.flipLines ? "red" : "green",
             color: chart.flipLines ? "red" : "green",
             position: "bottom",
             inside: true,
             labelRotation: 90,
             dashLength: 2,
             label: mapCursorOpenLabel
             });
             chart.panels[0].categoryAxis.guides.push({
             id: "mapCursorClose",
             date: mapCursorCloseDateObj,
             lineAlpha: 1.0,
             lineColor: chart.flipLines ? "green" : "red",
             color: chart.flipLines ? "green" : "red",
             position: "bottom",
             inside: true,
             labelRotation: 90,
             dashLength: 2,
             label: mapCursorCloseLabel
             });
             
             }
             }
             chart.validateNow();
             chart.validateData();
             }*/
        }
}
}

export {showTradingPeriodIndicator, hideTradingPeriod};
