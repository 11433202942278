<template>
    <span>
        <b-button v-on:click="toggle" size="sm"
                  v-bind:style="{color: 'white', backgroundColor: propertyValue ? 'darkgray' : 'black' }">
            {{label}}</b-button>
    </span>
</template>

<script>
    import {capitalizeFirstLetter} from "../js/main";

    export default {
        props: ['property', 'label', 'namespace'],
        methods: {
            toggle: function () {
                // console.log("toggle() starting.");
                let command = 'set' + capitalizeFirstLetter(this.property);
                console.log("command=", command, " to ", !this.propertyValue);
                this.$store.commit(this.namespace + '/' + command, !this.propertyValue);
            }
        },
        computed: {
            propertyValue() {
                return this.$store.state[this.namespace][this.property];
            }
        }
    }
</script>
