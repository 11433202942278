//import * as am4charts from "@amcharts/amcharts4/charts";
//import * as am4core from "@amcharts/amcharts4/core";
//import moment from 'moment';

/*var rsiColor = "teal";
 
 function addLastCandle() {
 console.log("addLastCandle() starting.");
 //  let graph = chart.panels[0].graphs.map(x => x.title);
 //  console.log("XXXXXXXXXXXXXXXXXXXX=", graph);
 try {
 let dataSetObj = chart.dataSets.find(x => (x.title.includes("bars")));
 var sourceData = dataSetObj.dataProvider;
 //  console.log("sourceData=", sourceData);
 var j = sourceData.length - 1;
 while (j > 0 && typeof sourceData[j].open === 'undefined') {
 // console.log(sourceData[j]);
 var barExists = typeof sourceData[j].open !== 'undefined';
 // console.log("barExists=", barExists);
 j--;
 }
 var previousLastBar = Object.assign({}, sourceData[j]);
 // var previousLastBar = Object.assign({}, chartData[chartData.length - 1]);
 // console.log("previousLastBar=", previousLastBar);
 
 let lastDate = previousLastBar.date;
 console.log("lastDate=", lastDate);
 let newLastDate = new Date(lastDate);
 newLastDate.setDate(newLastDate.getDate() + 1);
 previousLastBar.date = newLastDate;
 previousLastBar.open = previousLastBar.open + 10 * (0.5 - Math.random());
 previousLastBar.high = previousLastBar.high + 10 * (0.5 - Math.random());
 previousLastBar.low = previousLastBar.low + 10 * (0.5 - Math.random());
 previousLastBar.close = previousLastBar.close + 10 * (0.5 - Math.random());
 previousLastBar.volume = previousLastBar.volume + 10 * (0.5 - Math.random());
 previousLastBar.openInterest = previousLastBar.openInterest + 10 * (0.5 - Math.random());
 
 // chartData.push(previousLastBar);
 sourceData[j + 1] = previousLastBar;
 // console.log("chartData=", chartData);
 updateIndicators(newLastDate);
 } catch (ex) {
 console.log("Error:" + ex);
 } 
 }
 
 function updateIndicators(lastDate) {
 console.log("updateIndicators() starting.");
 let generalForm = generalForm;
 console.log("generalForm=", generalForm);
 ChangeStockGraphType();
 if (generalForm.addMACDPanel) {
 AddMacdPanel();
 }
 if (generalForm.addRSIPanel) {
 AddRsiPanel();
 }
 if (generalForm.addATRPanel) {
 AddAtrPanel();
 }
 if (generalForm.addBollinger) {
 AddBollinger();
 }
 
 chart.validateNow();
 chart.validateData();
 let newLastDate = new Date(lastDate);
 newLastDate.setDate(newLastDate.getDate() + 1);
 console.log("newLastDate=", newLastDate);
 
 var lastChartDate = chart.mainDataSet.dataProvider[chart.mainDataSet.dataProvider.length - 1].date;
 console.log("lastChartDate =", lastChartDate);
 console.log("generalForm.startDate =", generalForm.startDate + " generalForm.endDate =", generalForm.endDate);
 console.log("chart.startDate =", chart.startDate + " chart.endDate =", chart.endDate);
 if (newLastDate.getTime() > chart.endDate.getTime()) {
 chart.zoom(chart.startDate, newLastDate);
 }
 }
 
 function handleHideItem(event) {
 console.log("handleHideItem() starting.");
 let generalForm = generalForm;
 var hiddenItem = event.dataItem.title;
 var hiddenItems = generalForm.hiddenAmchartsItems.slice(0);
 // console.log("hiddenItem =", hiddenItem);
 // console.log("hiddenItems =", hiddenItems);
 if (typeof generalForm.hiddenAmchartsItems === 'undefined') {
 console.log("generalForm.hiddenAmchartsItems is undefined.");
 hiddenItems = [];
 } else {
 hiddenItems = generalForm.hiddenAmchartsItems.slice(0);
 }
 hiddenItems.push(hiddenItem.replace(/:/g, ''));
 if (typeof programVue != 'undefined') {
 programVue.$store.commit('generalForm/setHiddenAmchartsItems', hiddenItems);
 }
 generalForm.hiddenAmchartsItems = hiddenItems;
 // generalForm.generator = customSerialize().generator;  // If generator is PercentProfitableMapGenerator, generalForm will have erroneous value of "SeasonalGenerator". That's why this statement is needed.  
 updateState(generalForm);
 }
 
 function handleShowItem(event) {
 console.log("handleShowItem() starting.");
 let generalForm = generalForm;
 var shownItem = event.dataItem.title.replace(/:/g, '');
 console.log("shownItem =", shownItem);
 var hiddenItems = generalForm.hiddenAmchartsItems.slice(0);
 
 var index = hiddenItems.indexOf(shownItem);
 if (index !== -1) {
 hiddenItems.splice(index, 1);
 // console.log("hiddenItems =", hiddenItems);
 if (typeof programVue != 'undefined') {
 programVue.$store.commit('generalForm/setHiddenAmchartsItems', hiddenItems);
 }
 generalForm.hiddenAmchartsItems = hiddenItems;
 // generalForm.generator = customSerialize().generator;  // If generator is PercentProfitableMapGenerator, generalForm will have erroneous value of "SeasonalGenerator". That's why this statement is needed.  
 updateState(generalForm);
 }
 }*/

function removeNaNs(array, valueFieldName) {
    // console.log("removeNaNs() starting."); // This also removes weekends and days without data.
    //  console.log("valueFieldName=", valueFieldName);
    console.log("array=", array);
    let fields = Object.keys(array[0]);
    //  console.log("fields=", fields);
    if (typeof valueFieldName === 'undefined' || fields.indexOf(valueFieldName) === -1) {
        valueFieldName = "close";
    }
    // console.log("valueFieldName=", valueFieldName);

    let returnArray = array.filter(x => !isNaN(x[valueFieldName]));
    //let nAnArray = array.filter(x => isNaN(x[valueFieldName]));
    // console.log("nAnArray=", nAnArray);
    // console.log("returnArray=", returnArray);
    return returnArray;
}

function sd(objectArray, sdLength, valueFieldName) {
    //  console.log("sd() starting.");
    let fields = Object.keys(objectArray[0]);
    if (typeof valueFieldName === 'undefined' || fields.indexOf(valueFieldName) === -1) {
        valueFieldName = "close";
    }
    //  console.log("valueFieldName=", valueFieldName);

    let sdSeries = sma(objectArray, sdLength, valueFieldName);
    // console.log("sdSeries=", sdSeries);

    for (var j = 0; j < sdSeries.length; j++) {
        let obj = objectArray[j];
        let sd = 0;
        if (j > sdLength) {
            let avg = sdSeries[j][valueFieldName + "_sma_" + sdLength];
            let sumOfSquareDiffs = 0;
            for (var n = 0; n < sdLength; n++) {
                let index = j - n;
                // console.log("index=", index);
                let close = sdSeries[index][valueFieldName];
                // console.log("valueFieldName=", valueFieldName, " sdSeries[" + index + "][valueFieldName]=", sdSeries[index][valueFieldName]);
                let diff = close - avg;
                sumOfSquareDiffs = sumOfSquareDiffs + diff ** 2;
            }
            sd = Math.sqrt(sumOfSquareDiffs / sdLength);
        }
        obj["sd_" + sdLength] = sd;
    }
    return sdSeries;
}


function md(objectArray, mdLength, valueFieldName) {
    console.log("md() starting.");
    let fields = Object.keys(objectArray[0]);
    if (fields.indexOf(valueFieldName) == -1) {
        valueFieldName = "close";
    }
    console.log("valueFieldName=", valueFieldName);

    let mdSeries = sma(objectArray, mdLength, valueFieldName);
   // console.log("mdSeries=", mdSeries);

    for (var j = 0; j < mdSeries.length; j++) {
        let obj = objectArray[j];
        let md = 0;
        if (j >= mdLength - 1) {

            let avg = mdSeries[j][valueFieldName + "_sma_" + mdLength];
            let sumOfAbsoluteDiffs = 0;
            for (var n = 0; n < mdLength; n++) {
                let index = j - n;
                // console.log("index=", index);
                let close = mdSeries[index][valueFieldName];
                // console.log("valueFieldName=", valueFieldName, " mdSeries[" + index + "][valueFieldName]=", mdSeries[index][valueFieldName]);
                let diff = Math.abs(close - avg);
                sumOfAbsoluteDiffs = sumOfAbsoluteDiffs + diff;
            }
            md = sumOfAbsoluteDiffs / mdLength;
            obj[valueFieldName + "_md_" + mdLength] = md;
        }
    }
    return mdSeries;
}

function sma(objectArray, smaLength, valueFieldName) {
    // console.log("sma() starting.");
    // console.log("objectArray=", objectArray);
    let fields = Object.keys(objectArray[0]);
    // console.log("smaLength=", smaLength);
    if (typeof valueFieldName === 'undefined' || fields.indexOf(valueFieldName) === -1) {
        valueFieldName = "close";
    }
    // console.log("valueFieldName=", valueFieldName);

    let returnArray = [];
    let tempSma = parseFloat(objectArray[0][valueFieldName]);
    for (var j = 1; j < objectArray.length; j++) {
        let obj = objectArray[j];
        if (j <= smaLength) {
            let close = parseFloat(obj[valueFieldName]);
            // console.log("close=", close);
            tempSma = (tempSma * (j - 1) + close) / j;
        } else {
            tempSma = tempSma + (parseFloat(obj[valueFieldName]) - parseFloat(objectArray[j - smaLength][valueFieldName])) / smaLength;
        }
        obj[valueFieldName + "_sma_" + smaLength] = tempSma;
        // console.log("tempSma=", tempSma);
        // console.log("obj=", obj);
        returnArray.push(obj);
    }
    returnArray.unshift(objectArray[0]);
    // console.log("returnArray=", returnArray);
    return returnArray;
}

function ema(objectArray, valueFieldName, emaLength) {
    // console.log("ema() starting.");
    //  console.log("valueFieldName=", valueFieldName);
    //  console.log("objectArray=", objectArray);

    let fields = Object.keys(objectArray[0]);
    // console.log("fields=", fields);
    if (typeof valueFieldName === 'undefined' || fields.indexOf(valueFieldName) === -1) {
        valueFieldName = "close";
    }

    let k = 2 / (emaLength + 1);
    var returnArray = [];
    var tempEma = parseFloat(objectArray[0][valueFieldName]);
    for (var j = 1; j < objectArray.length; j++) {
        let obj = objectArray[j];
        let close = parseFloat(obj[valueFieldName]);
        tempEma = (close - tempEma) * k + tempEma;
        obj["ema_" + emaLength] = tempEma;
        returnArray.push(obj);
    }
// console.log("returnArray=", returnArray);
    return returnArray;
}

export  {removeNaNs, md, sd, sma, ema};