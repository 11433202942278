<template>
    <div style="font-weight: normal; color: black;" v-bind:id="namespace+'-playbackControl'">
        <b-form inline style="border-bottom: 1px solid gray; border-left: 0px solid gray; border-right: 0px solid gray;
                    padding: 3px; background-color: #E8E8E8"> 

            <b-button v-on:click="hidePlayback()" style="margin: 0 7px 0 0px; background-color: black;  color: white">hide</b-button>

            <span style="margin-right: 0px; margin-left: 2px; font-weight: bold"> start:</span>
            <b-form-datepicker v-model="start" :max="end" style="margin-right: 7px; margin-left: 2px; border: 1px solid gray;"></b-form-datepicker>

            <span style="margin-right: 0px; margin-left: 2px; font-weight: bold"> end:</span>
            <b-form-datepicker v-model="end" :min="start" style="margin-right: 11px; margin-left: 2px; border: 1px solid gray;"></b-form-datepicker>

            <span style="margin-left: 4px; margin-right: 5px; font-weight: bold"> interval:
                <b-form-select style="border:1px solid gray; width: auto; margin: 0px 1px 0 0px"
                               v-model="interval" :options="intervalOptions"> 
                </b-form-select>
                <span style="font-weight: normal">sec/frame</span>
            </span>
            <b-button v-on:click="$parent.rewind()" 
                      v-bind:disabled="i === 1" 
                      v-bind:style="{ backgroundColor: i === 1  ? 'gray' : 'darkblue' }"
                      style="margin-left: 12px; padding-bottom: -5px; border: 1px solid black; color: white">
                <b-icon icon="skip-backward-fill"></b-icon>
            </b-button>

            <b-button v-on:click="$parent.oneStepBackward()" 
                      v-bind:disabled="i == null || i <= 1" 
                      style="padding-top: 2px; border: 1px solid black; margin: 1px;  color: white"
                      v-bind:style="{ backgroundColor: (i == null || i <= 1) ? 'gray' : 'darkblue' }">
                <b-icon icon="skip-start-fill"></b-icon>
            </b-button>

            <b-button v-on:click="$parent.pause()" 
                      v-bind:disabled="i === null || i === 1" 
                      v-bind:style="{ backgroundColor: (i == null || i <= 1) ? 'gray' : 'darkblue' }"
                      style="margin: 0px;  border: 1px solid black; padding-top: 2px;  color: white">
                <b-icon icon="pause-fill"></b-icon>
            </b-button>

            <b-button v-on:click="$parent.oneStepForward()" 
                      v-bind:disabled="i === null" 
                      style="padding-top: 2px; border: 1px solid black; margin: 1px;  color: white"
                      v-bind:style="{ backgroundColor: i == null ? 'gray' : 'darkblue' }">
                <i class="fa fa-step-forward" aria-hidden="true" style="color: white; margin: 0 0px 0 0"></i>
            </b-button>

            <b-button v-on:click="$parent.play()" 
                      v-bind:disabled="i == null" 
                      style="margin-right: 16px; padding-top: 2px; border: 1px solid black;  color: white" 
                      v-bind:style="{ backgroundColor: i == null ? 'gray' : 'darkblue' }">
                <i class="fa fa-play" style="color: white; margin: 0 0px 0 0"></i>
            </b-button>

            <levels-button v-bind:namespace="namespace" style="margin: 1px;"></levels-button>                            

            <toggle-single-throw v-bind:namespace="namespace" v-bind:property="'showBreakpoints'" v-bind:label="'breakpoints'"
                 style="margin: 0px;"></toggle-single-throw>

            <table v-if="i !== null" style="color: darkblue; width: auto" border="0">
                <tr>
                    <td style="padding: 0px 3px 0 10px;  width: auto; font-weight: bold">
                        playback date: 
                    </td>
                    <td style="width: 140px">
                        {{moment(playbackData.date).format("MMM DD, YYYY dddd")}}
                    </td>
                    <td style="padding: 0px 3px 0 8px;  font-weight: bold">
                        close: 
                    </td>
                    <td style="">
                        <span v-if="playbackData.data">{{playbackData.close}}</span> 
                        <span v-if="!playbackData.data" style="color: red">no data</span>     
                    </td>
                </tr>
            </table> 


        </b-form>

        <b-form v-if="showBreakpoints" inline style="border-bottom: 1px solid gray; border-left: 0px solid gray; border-right: 0px solid gray;padding: 3px; background-color: #E8E8E8"> 
            <b-form-row align-h="start" style="border:0px solid gray">
                <b-col align-h="start">  

                    <b-form inline v-for="breakpoint in breakpoints" v-bind:key="breakpoint.date" style="white-space: nowrap">  
                        <b-form-datepicker v-model="breakpoint.date" :min="start" :max="end" style="width: 260px; ; margin: 0 12px 3px 0; border: 1px solid gray;"></b-form-datepicker>

                        <b> title:</b>    <span style="margin: 0px 10px 0 2px;">
                            <b-input style="width: 150px; border:1px solid gray;"
                                      v-model.number="breakpoint.title">
                            </b-input>
                        </span> 

                        <b> message:</b>    <span style="margin: 0px 10px 0 2px;">
                            <b-input style="width: 450px; border:1px solid gray;"
                                      v-model.number="breakpoint.message">
                            </b-input>
                        </span> 

                        <b-button variant="warning" v-on:click="removeBreakpoint(breakpoint.date)" style="margin: 1px; border: 1px solid darkgray">remove</b-button>

                    </b-form>

                    <b-button variant="warning" v-on:click="addBreakpoint()" style="margin: 1px; border: 1px solid darkgray">add breakpoint</b-button>

                </b-col>
            </b-form-row>
        </b-form>
    </div>
</template>

<script>

    import moment from "moment";
    import toggleSingleThrow from '@/components/toggle-single-throw';
    import levelsButton from '@/components/levels-button';

    export default {

        components: {
            toggleSingleThrow, levelsButton
        },
        mounted() {
            console.log("playback-control.vue starting.");
            //  this.$store.commit(this.namespace + '/playback/setI', null);

            if (this.$store.state[this.namespace].playback.start === null) {
                let start = moment().subtract(8, 'weeks').format('YYYY-MM-DD');
                this.$store.commit(this.namespace + '/playback/setStart', start);
            }
            console.log("this.$store.state[this.namespace].playback.end=", this.$store.state[this.namespace].playback.end);
            if (this.$store.state[this.namespace].playback.end === null) {
                this.$store.commit(this.namespace + '/playback/setEnd', this.$store.state[this.namespace].chartParameters.mainSeriesEnd);
            }
        },
        props: ['namespace', 'i'],
        data: function () {
            return {
                timer: null,
                intervalOptions: Array(5).fill(null).map((x, i) => ({value: i + 1, text: i + 1}))
            }
        },
        methods: {
            moment: function (date) {
                return moment(date);
            },
            hidePlayback() {
                this.$store.commit(this.namespace + '/setShowPlaybackControl', false);
            },
            removeBreakpoint(date) {
                console.log("removeBreakpoint() starting. date=", date);
                let newBreakpoints = this.breakpoints.filter(x => x.date !== date);
                console.log("newBreakpoints=", newBreakpoints);

                this.$store.commit(this.namespace + '/playback/setBreakpoints', newBreakpoints);
            },
            addBreakpoint() {
                console.log("addBreakpoint() starting.");
                console.log("this.breakpoints=", this.breakpoints);

                let emptyBreakpoint = {date: "", title: "", message: ""};
                console.log("emptyBreakpoint=", emptyBreakpoint);

                this.$store.commit(this.namespace + '/playback/addBreakpoint', emptyBreakpoint);
            }
        },
        computed: {
            playbackData() {
                let newData = this.$parent.newData[this.i - 1];
                // console.log("newData=", {...newData}, " this.end=", this.end);
                // console.log("typeof newData=", typeof newData);
                if(newData!=undefined)
                this.$root.$emit('replay_date_update', newData.date); //like this
                if (typeof newData !== 'undefined') {
                    let obj = JSON.parse(JSON.stringify(newData));
                    obj.date = newData.date;
                    obj.close = typeof newData !== 'undefined' ? newData.close : "";
                    obj.data = typeof newData.close !== 'undefined' ? true : false;
                    return obj;
                } else {
                    return {data: true, date: this.end};
                }
            },
            show: {
                get() {
                    return this.i === 1;
                },
                set() {}
            },
            breakpoints: {
                get() {
                    console.log("this.$store.state[this.namespace].playback=", this.$store.state[this.namespace].playback);
                    console.log("this.$store.state[this.namespace].playback.breakpoints=", this.$store.state[this.namespace].playback.breakpoints);
                    return this.$store.state[this.namespace].playback.breakpoints;
                },
                set(breakpoint) {
                    console.log("breakpoints set(). breakpoint=", breakpoint);
                }
            },
            showBreakpoints() {
                return this.$store.state[this.namespace].showBreakpoints;
            },
            interval: {
                get() {
                    return this.$store.state[this.namespace].playback.interval;
                },
                set(interval) {
                    this.$store.commit(this.namespace + '/playback/setInterval', interval);
                }
            },
            start: {
                get() {
                    return this.$store.state[this.namespace].playback.start;
                },
                set(start) {
                    this.$store.commit(this.namespace + '/playback/setStart', start);
                }
            },
            end: {
                get() {
                    return this.$store.state[this.namespace].playback.end;
                },
                set(end) {
                    this.$store.commit(this.namespace + '/playback/setEnd', end);
                }
            }
        }
    };

</script>