import {heightControl, removeNaNs} from '../main';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

function  rsi(objectArray, period) {
    console.log("rsi() starting.");
    //console.log("objectArray=", objectArray);

    for (var j = 0; j < objectArray.length; j++) {
        let obj = objectArray[j];
        // console.log("j=", j, " obj=", obj);
        if (j > 0) {
            obj.change = parseFloat(objectArray[j]["close"]) - parseFloat(objectArray[j - 1]["close"]);
        } else {
            obj.change = 0;
        }
        if (obj.change > 0) {
            obj.gains = obj.change;
            obj.losses = 0.0;
        } else {
            obj.gains = 0.0;
            obj.losses = -obj.change;
        }

        if (j < period) {
            obj.avgGains = 0.0;
            obj.avgLosses = 0.0;
            obj.rs = 0.0;
            obj.rsi = 0.0;
        } else if (j == period) {
            let sumGains = 0.0;
            let sumLosses = 0.0;
            for (var n = 0; n < period; n++) {
                let gains = objectArray[n].gains;
                let losses = objectArray[n].losses;
                sumGains = sumGains + gains;
                sumLosses = sumLosses + losses;
                // console.log("n=", n, " gains=", gains, " losses=", losses, " sumGains=", sumGains, " sumLosses=", sumLosses);
            }

            obj.avgGains = sumGains / period;
            obj.avgLosses = sumLosses / period;
            obj.rs = obj.avgGains / obj.avgLosses;
            obj.rsi = (100 - (100 / (1 + obj.rs)));
        } else {
            // console.log("j=", j, " obj=", obj);
            obj.avgGains = (objectArray[j - 1].avgGains * (period - 1) + obj.gains) / period;
            obj.avgLosses = (objectArray[j - 1].avgLosses * (period - 1) + obj.losses) / period;
            obj.rs = obj.avgGains / obj.avgLosses;
            obj.rsi = (100 - (100 / (1 + obj.rs)));
        }
        // console.log("obj=", obj);
    }
    // series.invalidateRawData();
    return objectArray;
}

function  removeRsiPanel(chart) {
    console.log("removeRsiPanel() starting.");
    let series = chart.map.getKey("rsi-series");
    chart.series.removeIndex(chart.series.indexOf(series)).dispose();

    let valueAxis = chart.map.getKey("rsi-axis");
    chart.yAxes.removeIndex(chart.yAxes.indexOf(valueAxis)).dispose();

    heightControl("removing", chart);
}

function createRsiGuides(chart) {
    let valueAxis = chart.map.getKey("rsi-axis");

    var lowerRange = valueAxis.axisRanges.create();
    lowerRange.value = 20;
    lowerRange.grid.stroke = "#505050";
    lowerRange.grid.strokeWidth = 1;
    lowerRange.grid.strokeDasharray = "2,2";
    lowerRange.grid.strokeOpacity = 1;
    lowerRange.label.inside = true;
    lowerRange.label.text = "20";
    lowerRange.label.fill = "#505050";
    lowerRange.label.align = "right";
    lowerRange.label.verticalCenter = "bottom";

    var upperRange = valueAxis.axisRanges.create();
    upperRange.value = 80;
    upperRange.grid.stroke = "#505050";
    upperRange.grid.strokeWidth = 1;
    upperRange.grid.strokeDasharray = "2,2";
    upperRange.grid.strokeOpacity = 1;
    upperRange.label.text = "80";
    upperRange.label.fill = "#505050";
    upperRange.label.align = "right";
    upperRange.label.verticalCenter = "bottom";
}

function  addRsiPanel(chart, rsiPeriod, generalForm) {
    // console.log("addRsiPanel() starting.");
    // console.log("rsiPeriod=", rsiPeriod);

    let formatString = "#." + "0".repeat(2);

    let valueAxis = chart.map.getKey("rsi-axis");
    let series = chart.map.getKey("rsi-series");
    var alreadyPresent = typeof valueAxis !== 'undefined';
    console.log("alreadyPresent=", alreadyPresent);

    if (!alreadyPresent) {
        valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        let axisPercentage = heightControl("adding", chart);
        valueAxis.height = am4core.percent(axisPercentage);

        series = chart.series.push(new am4charts.LineSeries());
    }

    valueAxis.id = "rsi-axis";
    if(location.href.indexOf("exercises")<=0) valueAxis.tooltip.disabled = true;
    valueAxis.zIndex = 3
// this makes gap between panels
    valueAxis.marginTop = 16;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.verticalCenter = "bottom";
    valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
//valueAxis.renderer.maxLabelPosition = 0.95;
    valueAxis.renderer.fontSize = "0.8em"
    valueAxis.renderer.gridContainer.background.fill = "white";
    valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
    valueAxis.title.text = rsiPeriod + "-day RSI";
    valueAxis.title.fontWeight = "bold";
    valueAxis.zoomable = false;

    series.id = "rsi-series";

    let objectArray = removeNaNs(chart.map.getKey("main-series").data);
    series.data = rsi(objectArray, rsiPeriod);
    series.dataFields.dateX = "date";
    series.clustered = false;
    series.dataFields.valueY = "rsi";
    series.yAxis = valueAxis;
    series.tooltipText = generalForm.chartParameters.balloons ? "rsi: {valueY.value.formatNumber('" + formatString + "')}" : "";
    series.name = "RSI";
    series.hiddenInLegend = true;
    series.legendSettings.labelText = "[{color}] rsi: {valueY.value.formatNumber('" + formatString + "')}";
    series.stroke = "teal";
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = "teal";
    createRsiGuides(chart);
}

function setRsiTooltips(chart, balloons) {
    let formatString = "#." + "0".repeat(2);
    console.log("formatString=", formatString);

    let series = chart.map.getKey("rsi-series");
    // console.log("rsiSeries=", series);
    if (typeof series !== 'undefined') {
        series.tooltipText = balloons ? "rsi: {valueY.value.formatNumber('" + formatString + "')}" : "";
    }
}

export {addRsiPanel, removeRsiPanel, setRsiTooltips};