import moment from 'moment';

function changePeriod(id, chart,generalForm) {
    console.log("changePeriod() starting. id=", id, " chart=", chart);
    let timePeriod = 365;
    let dateAxis = chart.map.getKey("date-axis");

    // console.log("id=", id);
    let  timeSpan = id.split("-");
    // console.log("timeSpan=", timeSpan);

    if (timeSpan[1] == "YYYY") {
        timePeriod = 365;
    } else if (timeSpan[0] == 6) {
        timePeriod = 188;
    } else if (timeSpan[0] == 3) {
        timePeriod = 94;
    }
    // console.log("timePeriod=" + timePeriod);
    if (timeSpan == "MAX") {
        // dateAxis.zoom(0, 1);
        dateAxis.start = 0.0;
        dateAxis.end = 1;
        dateAxis.keepSelection = false;
    } else {
        let lastChartDate = moment(generalForm.chartParameters.end, "YYYYMMDD");
        //  console.log("lastChartDate =", lastChartDate);

        var end;
        if (generalForm.instrument === "future") {
            end = moment().add(timePeriod / 2, 'days');
        } else {
            end = moment();
        }

        if (lastChartDate.isBefore(end)) {
            end = lastChartDate.clone();
        }

        var start = end.clone().subtract(timePeriod, 'days');
        // console.log("start=", start.toString());
        //  console.log("end=", end.toString());

        console.log("Zooming....................");
        dateAxis.zoomToDates(start.toDate(), end.toDate());
    }
}

export  {changePeriod};