import {spreadDigits, removeNaNs} from '../main';
import {sd} from './am4charts.main';
import * as am4charts from "@amcharts/amcharts4/charts";

function removeBollinger(chart) {
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("sma_20-series"))).dispose();
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("sd_minus-series"))).dispose();
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("sd_plus-series"))).dispose();
}

function addBollingerBands(chart, generalForm) {
    //  console.log("addBollingerBands() starting.");

    var smaSeries = chart.map.getKey("sma_20-series");
    var alreadyPresent = typeof smaSeries !== 'undefined';
    // console.log("alreadyPresent=", alreadyPresent);

    if (!alreadyPresent) {
        let selected = generalForm.selected[0];
        let digits = spreadDigits(selected, generalForm.instrument);
        let formatString = "#." + "0".repeat(digits);

        let period = 20;
        let mainData = chart.map.getKey("main-series").data;
        // console.log("removeNaNs(mainData)=", removeNaNs(mainData));

        let sdSeries = sd(removeNaNs(mainData), period);
        //console.log("sdSeries=", sdSeries);

        // chart.dataSets = chart.dataSets.filter(x => !["SMA_20:", "-2 SD:", "+2 SD:"].includes(x.title));
        sdSeries.forEach(x => {
            if (typeof x.close_sma_20 !== 'undefined') {
                x.sd_20_plus = (x.close_sma_20 + 2 * x.sd_20);
                x.sd_20_minus = (x.close_sma_20 - 2 * x.sd_20);
            }
        });

        smaSeries = chart.series.push(new am4charts.LineSeries());
        smaSeries.id = "sma_20-series";
        smaSeries.data = sdSeries;
        smaSeries.dataFields.valueY = "close_sma_20";
        smaSeries.dataFields.openValueY = "close_sma_20";
        smaSeries.dataFields.dateX = "date";
        smaSeries.name = "SMA_20";
        smaSeries.strokeWidth = 1;
        smaSeries.stroke = "darkblue";
        smaSeries.fillOpacity = 0.0;
        smaSeries.stacked = false;
        smaSeries.legendSettings.labelText = "[{color}]sma_20: {valueY.formatNumber('" + formatString + "')}";
        smaSeries.hiddenInLegend = true;
        // smaSeries.yAxis = chart.map.getKey("main-axis");

        var sdPlusSeries = chart.series.push(new am4charts.LineSeries());
        sdPlusSeries.id = "sd_minus-series";
        sdPlusSeries.data = sdSeries;
        sdPlusSeries.dataFields.openValueY = "sd_20_minus";
        sdPlusSeries.dataFields.valueY = "sd_20_plus";
        sdPlusSeries.dataFields.dateX = "date";
        sdPlusSeries.name = "sd_20_plus";
        sdPlusSeries.strokeWidth = 1;
        sdPlusSeries.fillOpacity = 0.3;
        sdPlusSeries.fill = "#D6EAF8";
        sdPlusSeries.stroke = "lightblue";
        sdPlusSeries.stacked = false;
        sdPlusSeries.legendSettings.labelText = "[{color}]+sd: {valueY.formatNumber('" + formatString + "')}";
        sdPlusSeries.hiddenInLegend = true;

        var sdMinusSeries = chart.series.push(new am4charts.LineSeries());
        sdMinusSeries.id = "sd_plus-series";
        sdMinusSeries.data = sdSeries;
        sdMinusSeries.dataFields.valueY = "sd_20_minus";
        sdMinusSeries.dataFields.dateX = "date";
        sdMinusSeries.name = "sd_20_minus";
        sdMinusSeries.strokeWidth = 1;
        sdMinusSeries.stroke = "lightblue";
        sdMinusSeries.legendSettings.labelText = "[{color}]-sd: {valueY.formatNumber('" + formatString + "')}";
        sdMinusSeries.hiddenInLegend = true;
    } else {
        smaSeries.show();
        chart.map.getKey("sd_minus-series").show();
        chart.map.getKey("sd_plus-series").show();
    }
}

export {addBollingerBands, removeBollinger};