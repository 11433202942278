import {heightControl, spreadDigits} from '../main';
import {ema, removeNaNs} from './am4charts.main';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

/*const macd = {
 mounted() {
 console.log("mixins/macd.js mounted() starting. this.namespace=", this.namespace);
 },
 computed: {
 addMACDPanel() {
 return this.$store.state[this.namespace].addMACDPanel;
 }
 },
 watch: {
 addMACDPanel: function (addMACDPanel) {
 // console.log("watch addMACDPanel = ", addMACDPanel);
 if (typeof this.chart.plot.series !== 'undefined') {
 this.generalForm.addMACDPanel = addMACDPanel;
 addMACDPanel ? this.addPanel(this.chart.plot, this.generalForm) : this.removeMacdPanel(this.chart.plot);
 }
 },
 balloons: function (balloons) {
 if (typeof this.chart.plot.series !== 'undefined') {
 let macdSeries = this.chart.plot.map.getKey("macd-series");
 let signalSeries = this.chart.plot.map.getKey("signal-series");
 let histogramSeries = this.chart.plot.map.getKey("histogram-series");
 if (typeof macdSeries !== 'undefined') {
 macdSeries.tooltipText = balloons ? "macd: {valueY.value}" : "";
 signalSeries.tooltipText = balloons ? "signal: {valueY.value}" : "";
 histogramSeries.tooltipText = balloons ? "histogram: {valueY.value}" : "";
 }
 }
 }
 },
 methods: { */
function removeMacdPanel(chart) {
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("macd-series"))).dispose();
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("signal-series"))).dispose();
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("histogram-series"))).dispose();

    chart.yAxes.removeIndex(chart.yAxes.indexOf(chart.map.getKey("macd-axis"))).dispose();
    heightControl("removing", chart);
}

function addMacdPanel(chart, generalForm) {
    console.log("addPanel() starting.");
    let selected = generalForm.selected[0];
    console.log("selected=", selected);
    let digits = spreadDigits(selected, generalForm.instrument);
    let formatString = "#." + "0".repeat(digits);
    console.log("formatString=", formatString);

    let slowPeriod = 26;
    let fastPeriod = 12;
    var fastPropName = "ema_" + fastPeriod;
    var slowPropName = "ema_" + slowPeriod;
    let mainData = chart.map.getKey("main-series").data;

    let emaSeries = ema(removeNaNs(mainData), "close", fastPeriod);
    emaSeries = ema(emaSeries, "close", slowPeriod);
    emaSeries.forEach(x => {
        // if (typeof x.close_sma_20 !== 'undefined') {
        x.macd = x[fastPropName] - x[slowPropName];
        //  }
    });
    // console.log("emaSeries=", emaSeries);

    let signalPeriod = 9;
    let signal = ema(emaSeries, "macd", signalPeriod);

    let axisPercentage = heightControl("adding", chart);

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.id = "macd-axis";
    valueAxis.tooltip.disabled = true;
    valueAxis.height = am4core.percent(axisPercentage);
    valueAxis.zIndex = 3
// this makes gap between panels
    valueAxis.marginTop = 16;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.verticalCenter = "bottom";
    valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
//valueAxis.renderer.maxLabelPosition = 0.95;
    valueAxis.renderer.fontSize = "0.8em"
    valueAxis.renderer.gridContainer.background.fill = "white";
    valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
    valueAxis.title.text = "MACD(26,12,9)";
    valueAxis.title.fontWeight = "bold";
    valueAxis.zoomable = false;

    var macdSeries = chart.series.push(new am4charts.LineSeries());
    macdSeries.id = "macd-series";
    macdSeries.data = signal;
    macdSeries.dataFields.dateX = "date";
    macdSeries.clustered = false;
    macdSeries.dataFields.valueY = "macd";
    macdSeries.yAxis = valueAxis;
    macdSeries.tooltipText = generalForm.chartParameters.balloons ? "macd: {valueY.value.formatNumber('" + formatString + "')}" : "";
    macdSeries.name = "MACD";
    macdSeries.hiddenInLegend = true;
    macdSeries.legendSettings.labelText = "[{color}] macd: {valueY.value.formatNumber('" + formatString + "')}";
    macdSeries.stroke = "#7D0552";
    macdSeries.tooltip.getFillFromObject = false;
    macdSeries.tooltip.background.fill = "#7D0552";

    var signalSeries = chart.series.push(new am4charts.LineSeries());
    signalSeries.data = signal;
    signalSeries.id = "signal-series";
    signalSeries.dataFields.dateX = "date";
    signalSeries.clustered = false;
    signalSeries.dataFields.valueY = "ema_9";
    signalSeries.yAxis = valueAxis;
    signalSeries.tooltipText = generalForm.chartParameters.balloons ? "signal: {valueY.value.formatNumber('" + formatString + "')}" : "";
    signalSeries.name = "SIGNAL";
    signalSeries.hiddenInLegend = true;
    signalSeries.legendSettings.labelText = "[{color}] ema_9: {valueY.value.formatNumber('" + formatString + "')}";
    signalSeries.stroke = "#6698FF";
    signalSeries.tooltip.getFillFromObject = false;
    signalSeries.tooltip.background.fill = "#6698FF";

    signal.forEach(x => {
        // if (typeof x.close_sma_20 !== 'undefined') {
        x.histogram = (x.macd - x.ema_9);
        //  }
    });

    var histogramSeries = chart.series.push(new am4charts.ColumnSeries());
    histogramSeries.data = signal;
    histogramSeries.id = "histogram-series";
    histogramSeries.dataFields.dateX = "date";
    histogramSeries.clustered = false;
    histogramSeries.dataFields.valueY = "histogram";
    histogramSeries.yAxis = valueAxis;
    histogramSeries.tooltipText = generalForm.chartParameters.balloons ? "histogram: {valueY.value.formatNumber('" + formatString + "')}" : "";
    histogramSeries.name = "HISTOGRAM";
    histogramSeries.hiddenInLegend = true;
    histogramSeries.legendSettings.labelText = "[{color}] hist: {valueY.value.formatNumber('" + formatString + "')}";
    histogramSeries.fill = "#FF4500";
    histogramSeries.stroke = "darkred";
    histogramSeries.tooltip.getFillFromObject = false;
    histogramSeries.tooltip.background.fill = "#FF4500";

    console.log("valueAxis.minZoomed=", valueAxis.minZoomed);
    console.log("valueAxis.maxZoomed=", valueAxis.maxZoomed);
    valueAxis.min = valueAxis.minZoomed;
    valueAxis.max = valueAxis.maxZoomed;
}

function setMacdTooltips(chart, balloons, generalForm) {
    let selected = generalForm.selected[0];
    console.log("selected=", selected);
    let digits = spreadDigits(selected, generalForm.instrument);
    let formatString = "#." + "0".repeat(digits);
    console.log("formatString=", formatString);
    
    let macdSeries = chart.map.getKey("macd-series");
    let signalSeries = chart.map.getKey("signal-series");
    let histogramSeries = chart.map.getKey("histogram-series");
    // console.log("rsiSeries=", series);
    if (typeof macdSeries !== 'undefined') {
        macdSeries.tooltipText = balloons ? "macd: {valueY.value.formatNumber('" + formatString + "')}" : "";
        signalSeries.tooltipText = balloons ? "[{color}] ema_9: {valueY.value.formatNumber('" + formatString + "')}" : "";
        histogramSeries.tooltipText = balloons ? "histogram: {valueY.value.formatNumber('" + formatString + "')}" : "";
    }
}

export {addMacdPanel, removeMacdPanel, setMacdTooltips};