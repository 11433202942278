import {heightControl, contractNameDecomposer}
from '../main';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import $ from "jquery";

function removeCotPanel(chart) {
   // console.log("removeCotPanel() starting.");
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("commercial-series"))).dispose();
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("nonCommercial-series"))).dispose();
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("smallTrader-series"))).dispose();

    chart.yAxes.removeIndex(chart.yAxes.indexOf(chart.map.getKey("cot-axis"))).dispose();
    heightControl("removing", chart);
}

function addCotPanel(chart, mainParsedData, generalForm, vm) {
   // console.log("addCotPanel() starting.");
    let commercialSeries = chart.map.getKey("commercial");
    // console.log("commercialSeries=", commercialSeries);
    let formatString = "#,###.";

    var alreadyPresent = typeof commercialSeries !== 'undefined';
   // console.log("alreadyPresent=", alreadyPresent);

    if (!alreadyPresent) {

        let commodity = contractNameDecomposer(generalForm.sampleContract[0]).commoditySymbol;
      //  console.log("vm=", vm);
        let url = vm.$store.state.siteData.baseUrl + vm.$store.state.siteData.contextName + "/CotServlet?commodity=" + commodity;
        $.ajax({
            url: url,
            type: "GET",
            success: function (data) {
                let temp = JSON.parse(data);
               // console.log("temp=", temp);
                if (temp.unit === "No COT.") {
                    let messageExists = document.getElementById("no_cot_message");
                    if (!messageExists) {
                        $("#chartdiv").before("</H2><H3 id='no_cot_message' style='color: red; text-align: left; padding: 2px'>There is no commitment of traders data for this commodity.</H3>");
                    }
                } else {
                    $("#no_cot_message").remove();
                   // let start = generalForm.chartParameters.start;
                   // console.log("start=", start);

                    let cotChartData = chart.map.getKey("main-series").data;
                    cotChartData.forEach(function (bar) {
                        let cotBar = temp.values.find(x => x[0] == bar.date.replace(/-/g, ""));
                        if (typeof cotBar !== 'undefined') {
                            bar.commercial = cotBar[1];
                            bar.nonCommercial = cotBar[2];
                            bar.smallTrader = cotBar[3];
                        }
                    });

                  //  console.log("cotChartData=", cotChartData);
                    mainParsedData.cot = cotChartData;
                    let axisPercentage = heightControl("adding", chart);

                    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.id = "cot-axis";
                    valueAxis.tooltip.disabled = true;
                    valueAxis.height = am4core.percent(axisPercentage);
                    valueAxis.zIndex = 3;
// this makes gap between panels
                    valueAxis.marginTop = 16;
                    valueAxis.renderer.baseGrid.disabled = true;
                    valueAxis.renderer.inside = true;
                    valueAxis.renderer.labels.template.verticalCenter = "bottom";
                    valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
//valueAxis.renderer.maxLabelPosition = 0.95;
                    valueAxis.renderer.fontSize = "0.8em";
                    valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
                    valueAxis.title.text = "COT of " + commodity;
                    valueAxis.title.fontWeight = "bold";
                    valueAxis.zoomable = false;

                    var commercialSeries = chart.series.push(new am4charts.LineSeries());
                    commercialSeries.id = "commercial-series";
                    commercialSeries.data = cotChartData;
                    commercialSeries.dataFields.valueY = "commercial";
                    commercialSeries.dataFields.dateX = "date";
                    commercialSeries.yAxis = valueAxis;
                    commercialSeries.name = "commercialSeries";
                    commercialSeries.strokeWidth = 1;
                    commercialSeries.stroke = "red";
                    commercialSeries.fillOpacity = 0.0;
                    commercialSeries.stacked = false;
                    commercialSeries.legendSettings.labelText = "[{color}]com: {valueY}";
                    commercialSeries.hiddenInLegend = true;
                    commercialSeries.tooltipText = generalForm.chartParameters.balloons ? "commercial: {valueY.value.formatNumber('" + formatString + "')}" : "";
                    commercialSeries.fill = "red";
                    commercialSeries.showOnInit = false;

                    var nonCommercialSeries = chart.series.push(new am4charts.LineSeries());
                    nonCommercialSeries.id = "nonCommercial-series";
                    nonCommercialSeries.data = cotChartData;
                    nonCommercialSeries.dataFields.valueY = "nonCommercial";
                    nonCommercialSeries.dataFields.dateX = "date";
                    nonCommercialSeries.yAxis = valueAxis;
                    nonCommercialSeries.name = "nonCommercialSeries";
                    nonCommercialSeries.strokeWidth = 1;
                    nonCommercialSeries.stroke = "blue";
                    nonCommercialSeries.fillOpacity = 0.0;
                    nonCommercialSeries.stacked = false;
                    nonCommercialSeries.legendSettings.labelText = "[{color}]non-com: {valueY}";
                    nonCommercialSeries.hiddenInLegend = true;
                    nonCommercialSeries.tooltipText = generalForm.chartParameters.balloons ? "non-commercial: {valueY.value.formatNumber('" + formatString + "')}" : "";
                    nonCommercialSeries.fill = "blue";
                    nonCommercialSeries.showOnInit = false;

                    var smallTraderSeries = chart.series.push(new am4charts.LineSeries());
                    smallTraderSeries.id = "smallTrader-series";
                    smallTraderSeries.data = cotChartData;
                    smallTraderSeries.dataFields.valueY = "smallTrader";
                    smallTraderSeries.dataFields.dateX = "date";
                    smallTraderSeries.yAxis = valueAxis;
                    smallTraderSeries.name = "smallTraderSeries";
                    smallTraderSeries.strokeWidth = 1;
                    smallTraderSeries.stroke = "black";
                    smallTraderSeries.stacked = false;
                    smallTraderSeries.tooltipText = generalForm.chartParameters.balloons ? "small trader: {valueY.value.formatNumber('" + formatString + "')}" : "";
                    smallTraderSeries.fill = "black";
                    smallTraderSeries.hiddenInLegend = true;
                    smallTraderSeries.legendSettings.labelText = "[{color}]sm trader: {valueY}";
                    smallTraderSeries.showOnInit = false;

                    valueAxis.events.once("startendchanged", () => {
                        //chart.zoomAxes(chart.yAxes, {start: 0, end: 1});
                       // console.log("valueAxis._minReal=", valueAxis._minReal);
                      //  console.log("valueAxis._maxReal=", valueAxis._maxReal);

                        valueAxis.zoomToValues(valueAxis._minReal, valueAxis._maxReal);

                        // let dateAxis = chart.map.getKey("date-axis");
                        // dateAxis.zoomToDates(0,1);

                    });
                }
            },
            fail: function (data) {
                console.log("ajax call failed.");
                $("#progress").hide();
                $("#button").show();
                console.log(data);
            }
        });
    }
}

function setCotTooltips(chart, balloons) {
    let formatString = "#,###.";

    let commercialSeries = chart.map.getKey("commercial-series");
    let nonCommercialSeries = chart.map.getKey("nonCommercial-series");
    let smallTraderSeries = chart.map.getKey("smallTrader-series");
    // console.log("rsiSeries=", series);
    if (typeof commercialSeries !== 'undefined') {
        commercialSeries.tooltipText = balloons ? "commercial: {valueY.value.formatNumber('" + formatString + "')}" : "";
        nonCommercialSeries.tooltipText = balloons ? "non-commercial: {valueY.value.formatNumber('" + formatString + "')}" : "";
        smallTraderSeries.tooltipText = balloons ? "small trader: {valueY.value.formatNumber('" + formatString + "')}" : "";
    }
}

export {addCotPanel, removeCotPanel, setCotTooltips};